<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
	<Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Container-->
    <div class="container">
      <div class="card card-custom gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">{{ $t('dash.total_amount') }}</span>
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-6 pb-0 mt-n2">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <!--begin::Table head-->
              <thead>
                <tr class="border-0">
                  <th class="p-0"></th>
                  <th class="p-0"></th>
                  <th class="p-0"></th>
                  <th class="p-0"></th>
                </tr>
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody>
                <tr v-if="isLoading">
                  <td colspan="4" class="text-center">{{ $t('commons.data_loading') }}</td>
                </tr>
                <tr v-else-if="total_user_balances.length === 0">
                  <td colspan="4" class="text-center">{{ $t('commons.no_data_available') }}</td>
                </tr>
                <tr v-else v-for="(item, index) in total_user_balances" :key="'total-balance' + index">
                  <td>
                    <div class="d-flex align-items-center">
                      <span class="bullet bullet-bar align-self-stretch mr-5" :style="{ background: randomColor() }"></span>
                      <div class="d-flex justify-content-start flex-column" v-if="item.total">
                        <span class="text-dark font-weight-bolder mb-1 fs-6">{{ $t('total') }} {{ $te(`dash.${index}`) ? $t(`dash.${index}`) : item.total.title }}</span>
                        <span v-if="index !== 'TRY' && index != 'USD'" class="text-dark font-weight-bold fs-7">{{ item.total.symbol }} {{ item.total.total }}</span>
                        <span class="text-dark font-weight-bold fs-7">{{ getSymbolFromExchangeRateByCurrency(item.total) }} {{ getTotalFromExchangeRateByCurrency(item.total) }}</span>
                        <span class="text-muted font-weight-bold fs-7">{{ getSymbolFromExchangeRateByCurrency(item.total, 'usd') }} {{ getTotalFromExchangeRateByCurrency(item.total, 'usd') }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-content-start flex-column" v-if="item[30]">
                      <span class="text-dark font-weight-bolder d-block mb-1 fs-6">{{ $t('dash.dealer_share') }}</span>
                      <span v-if="index !== 'TRY' && index != 'USD'" class="text-dark font-weight-bold fs-7">{{ item[30].symbol }} {{ item[30].total }}</span>
                      <span class="text-dark font-weight-bold fs-7">{{ getSymbolFromExchangeRateByCurrency(item[30]) }} {{ getTotalFromExchangeRateByCurrency(item[30]) }}</span>
                      <span class="text-muted font-weight-bold fs-7">{{ getSymbolFromExchangeRateByCurrency(item[30], 'usd') }} {{ getTotalFromExchangeRateByCurrency(item[30], 'usd') }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-content-start flex-column" v-if="item['10_20']">
                      <span class="text-dark font-weight-bolder d-block mb-1 fs-6">{{ $t('dash.sk_share') }}</span>
                      <span v-if="index !== 'TRY' && index != 'USD'" class="text-dark font-weight-bold fs-7">{{ item['10_20'].symbol }} {{ item['10_20'].total }}</span>
                      <span class="text-dark font-weight-bold fs-7">{{ getSymbolFromExchangeRateByCurrency(item['10_20']) }} {{ getTotalFromExchangeRateByCurrency(item['10_20']) }}</span>
                      <span class="text-muted font-weight-bold fs-7">{{ getSymbolFromExchangeRateByCurrency(item['10_20'], 'usd') }} {{ getTotalFromExchangeRateByCurrency(item['10_20'], 'usd') }}</span>
                    </div>
                  </td>
                  <td>
                      <div class="d-flex justify-content-start flex-column" v-if="item.ofinans">
                        <span class="text-dark font-weight-bolder mb-1 fs-6">{{ $t('dash.miraclecash_share') }}</span>
                        <span v-if="index !== 'TRY' && index != 'USD'" class="text-dark font-weight-bold fs-7">{{ item.ofinans.symbol }} {{ item.ofinans.total }}</span>
                        <span class="text-dark font-weight-bold fs-7">{{ getSymbolFromExchangeRateByCurrency(item.ofinans) }} {{ getTotalFromExchangeRateByCurrency(item.ofinans) }}</span>
                        <span class="text-muted font-weight-bold fs-7">{{ getSymbolFromExchangeRateByCurrency(item.ofinans, 'usd') }} {{ getTotalFromExchangeRateByCurrency(item.ofinans, 'usd') }}</span>
                      </div>
                  </td>
                </tr>
              </tbody>
              <!--end::Table body-->
            </table>
            <!--end::Table-->
          </div>
          <!--end::Table container-->
        </div>
        <!--begin::Body-->
      </div>
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'

export default {
  name: 'Dashboard',
  data() {
    return { }
  },
  components: {
    Breadcrumb
  },
  computed: {
    ...mapState({
      total_user_balances: state => state.currencies.total_user_balances,
      isLoading: state => state.currencies.isLoading
    }),
    breadcrumb_data() {
      return {
        title: this.$t('dash.title')
      }
    }
  },
  methods: {
    ...mapActions('currencies', ['GET_TOTAL_USER_BALANCES']),
    randomColor() {
      return `#${Math.floor(Math.random()*16777215).toString(16)}`
    },
    getSymbolFromExchangeRateByCurrency(item, currency="try") {
      return item?.currency_exchange_rate[currency].symbol;
    },
    getTotalFromExchangeRateByCurrency(item, currency="try") {
      return item?.currency_exchange_rate[currency].total;
    }
  },
  created() {
    this.GET_TOTAL_USER_BALANCES();
  }
}
</script>
