import Vue from 'vue';
import ServicesOld from "../../config/_axios_old";
import API_OLD from '@/api/index_old';
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg } from '../../utils';

const global = {
  state: () => ({
    isLoading: true,
    searchUserIsLoading: false,
    userList: [],
    showCreateWalletModal: false,
    lang: sessionStorage.getItem('lang'),
    currency: [],
    crypto: [],
    isLoadingOverlay: false
  }),
  mutations: {
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SEARCH_IS_LOADING(state, payload) {
      state.searchUserIsLoading = payload;
    },
    SET_SEARCH_KEEPER(state, payload) {
      state.userList = payload;
    },
    SET_CREATE_WALLET_MODAL(state, payload) {
      state.showCreateWalletModal = payload
    },
    SET_CURRENCY(state, payload) {
      state.currency = [];
      for (let key in payload.data) {
        let object = payload.data[key];
        state.currency.push(object);
      }
      console.log("currency: ",state.currency)
    },
    SET_OVERLAY_LOADER(state, payload) {
      state.isLoadingOverlay = payload;
    }
  },
  actions: {
    GET_EXCEL({ commit }, params) {
      // console.log("params: ",params)
      commit('SET_OVERLAY_LOADER', true)
      ServicesOld.get(params.url, {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        params: params?.reqPayload || {}
      })
        .then(response => {
            commit('IS_LOADING', false);
            const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = params.label
            link.click()
            URL.revokeObjectURL(link.href)
        })
        .catch(err => {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        })
        .finally(() => {
          commit('SET_OVERLAY_LOADER', false);
        })
    },
    async GET_PDF({ commit }, params) {
      commit('SET_OVERLAY_LOADER', true)
      try {
        const response = await ServicesOld.get(params.url, {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        });
        commit('IS_LOADING', false);
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = params.label
        link.click()
        URL.revokeObjectURL(link.href)
        return response;
      } catch (err) {
        // console.log("err: ",err.response)
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_OVERLAY_LOADER', false)
      }
    },
    async GET_MULTI({ commit }, params) {
      commit('SET_OVERLAY_LOADER', true)
      try {
        const response = await ServicesOld.get(params.url, {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        });
        commit('IS_LOADING', false);
        console.log(params)
        const blob = new Blob([response.data], { type: params.type });
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = params.label
        link.click()
        URL.revokeObjectURL(link.href)
        return response;
      } catch (err) {
        // console.log("err: ",err.response)
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_OVERLAY_LOADER', false)
      }
    },
    SEARCH_USER_KEEPER({ commit }, params) {
      params = params ? escape(params) : '';
      commit('SEARCH_IS_LOADING', true);
      ServicesOld.get(`${API_OLD.user_search}?type=invidual&search=${params}`)
        .then(res => {
          console.log("SEARCH_KEEPER: ",res)
          commit('SEARCH_IS_LOADING', false);
          commit('SET_SEARCH_KEEPER', res.data.results);
        })
        .catch(err => {
          console.log("err: ",err)
          commit('SEARCH_IS_LOADING', false);
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    GET_CURRENCY({ commit }) {
      return new Promise((resolve, reject) => {
        ServicesOld.get(`${API_OLD.finance_currencies}`)
          .then((res) => {
            commit('SET_CURRENCY', res.data?.data);
            commit('IS_LOADING', false);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            console.log("err: ",err.response)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          });
      })
    },
  },
  getters: {
    getCrypto(state) {
      return state.currency.filter(function (currency) {
        return currency.crypto && currency.quicksale;
      });
    },
    getCurrency(state) {
      return state.currency.filter(function (currency) {
        return currency;
      });
    }
  },
  namespaced: true
}

export default global;