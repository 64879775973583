import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './config/_i18n';
import store from './store'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueTheMask from 'vue-the-mask'
import "@/plugins/datatable";
import VueCurrencyInput from 'vue-currency-input';
import FormSubmitBtn from './components/custom/FormSubmitBtn.vue';
import "./config/_vee_validate";

const vueCurrencyInputOptions = { globalOptions: { precision: 2, currency: false } };
Vue.use(VueCurrencyInput, vueCurrencyInputOptions);

Vue.use(VueTheMask)

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true
});

Vue.component('form-submit-btn', FormSubmitBtn);

// global filters
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('uppercase', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.split(' ').map(word => word.toUpperCase()).join(' ')
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
