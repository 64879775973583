import Vue from 'vue';
import API_OLD from '@/api/index_old';
import ServicesOld from "../../config/_axios_old";
import router from '../../router/index';
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';

const customer = {
  state: () => ({
    isLogin: localStorage.getItem('isLogin') && localStorage.getItem('userToken') || '',
    login: {
      email: '',
      password: ''
    },
    user: {},
    permissions: null,
    isLoading: false,
    otp: {
      code: ''
    },
    googleAut: false,
    twoFaScreen: false,
    showOtpModal: false,
    passwordMail: {
      email: ''
    },
    passwordReset: {
      token: '',
      email: '',
      password: '',
      password_confirmation: ''
    },
    rememberMe: false,
    qrCode: {
      QR_Image: "",
      email: "",
      secret: ""
    }
  }),
  mutations: {
    SET_LOGIN(state, payload) {
      // console.log(payload)
      localStorage.setItem('userToken', payload.access_token);
      localStorage.removeItem('isLogin');
      state.showOtpModal = true;
      // state.login = { email: '', password: '' };
      state.otp = { code: '' };
      state.googleAut = payload.googleAut;
      state.twoFaScreen = payload.googleAut;
    },
    SET_OTP_LOGIN(state, payload) {
      // console.log(payload)
      localStorage.setItem('userToken', payload.employeeToken);
      localStorage.setItem('isLogin', true);
      state.isLogin = true;
      state.rememberMe ? 
        localStorage.setItem('_rememberLogin', state.login.email) :
        localStorage.setItem('_rememberLogin', null)
      state.login = { email: '', password: '' };
      state.showOtpModal = false;
      state.otp = { code: '' };
      // console.log(payload)
      router.push({ path: '/' });
      location.reload();
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_PERMISSION(state, payload) {
      state.permissions = payload;
    },
    SET_LOGOUT(state) {
      localStorage.removeItem('userToken');
      localStorage.removeItem('isLogin');
      state.isLogin = false;
      // window.location = '/login';
      router.push({ path: '/login' });
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    closeModal(state, payload) {
      // console.log(payload)
      state.showOtpModal = payload;
      state.otp = { code: '' };
    },
    updateScreen(state, payload) {
      state.twoFaScreen = payload;
    },
    updateToken(state, payload) {
      state.passwordReset.token = payload;
    },
    toggleRememberMe(state, payload = null) {
      state.rememberMe = payload ? payload : !state.rememberMe;
    },
    setRememberedEmail(state, payload) {
      state.login.email = payload;
    },
    SET_QR_CODE(state, payload) {
      state.qrCode = payload;
    }
  },
  actions: {
    POST_LOGIN({ state, commit }) {
      commit('IS_LOADING', true);
      ServicesOld.post(API_OLD.login, state.login)
        .then(res => {
          // console.log("POST_LOGIN: ",res.data.login)
          if (res.data.status == 'command') {
            commit('SET_LOGIN', res.data);
            commit('IS_LOADING', false);
          }
          else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err.response)
          commit('IS_LOADING', false);
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_PASSWORD_MAIL({ state }) {
      ServicesOld.post(API_OLD.password_mail, state.passwordMail)
        .then(res => {
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_PASSWORD_RESET({ state }) {
      ServicesOld.post(API_OLD.password_reset, state.passwordReset)
        .then(res => {
          Vue.$toast.success(getSuccessServiceMsg(res));
          // window.location = '/login'
          router.push({ name: 'Login' })
        })
        .catch(err => {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_LOGIN_OTP({ state, commit }) {
      ServicesOld.post(API_OLD.login_check, state.otp)
        .then((res) => {
          // console.log("POST_LOGIN_OTP--: ",res)
          if (res.data.status == 'ok') {
            commit('SET_OTP_LOGIN', res.data);
            commit('IS_LOADING', false);
          }
          else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    GET_USER({ commit }) {
      return new Promise((resolve, reject) => {
        ServicesOld.get(API_OLD.me)
          .then(res => {
            // console.log("get_user: ",res.data)
            resolve(res);
            commit('SET_USER', res.data);
          })
          .catch(err => {
            console.log("err: ",err.response)
            reject(err);
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          });
      })
    },
    GET_PERMISSION({ commit }) {
      ServicesOld.get(API_OLD.permission)
          .then(res => {
            //console.log("GET_PERMISSION: ",res.data.permissions)
            commit('SET_PERMISSION', res.data.permissions);
          })
          .catch(err => {
            console.log("err: ",err.response)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          });
    },

    GET_LOGOUT({ commit }) {
      commit('SET_LOGOUT');
      commit('IS_LOADING', false);
    },
    POST_SEND_CODE() {
      ServicesOld.post(API_OLD.send_code)
        .then(res => {
          // console.log("POST_SEND_CODE: ",res.data)
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    CREATE_TWO_FACTOR() {
      ServicesOld.post(API_OLD.create_two_factor)
        .then(res => {
          // console.log(res.data)
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_LOGIN_OTP_2FA({ state, commit }) {
      ServicesOld.post(API_OLD.login_2fa_check, state.otp)
        .then((res) => {
          // console.log("POST_LOGIN_OTP--: ",res)
          if (res.data.status == 'ok') {
            commit('SET_OTP_LOGIN', res.data);
            commit('IS_LOADING', false);
          }
          else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    GET_2FA_QR_CODE({ commit }, params) {
      const secret = params.key1, email = params.key2, time = params.key3 ;
      ServicesOld.get(API_OLD.twoFactorQrCode({ secret, email, time }))
        .then(res => {
            commit('SET_QR_CODE',res.data)
        })
        .catch(err => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_2FA_QR_CODE_CHANGE({ state }) {
      ServicesOld.post(API_OLD.twoFactorChange, {val1: state.qrCode.secret, val2: state.qrCode.email})
      .then(res => {
        if(res.data.status == 'ok'){
          Vue.$toast.success(getSuccessServiceMsg(res));
          router.push({ path: '/' });
        }
        else Vue.$toast.error(getErrorServiceMsg(res));
      })
      .catch(err => {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
      });
    }
  },
  getters: {
    isAuthenticated: state => !!state.isLogin
  },
  namespaced: true
}

export default customer;
