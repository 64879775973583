<template>
	<div class="aside aside-left aside-fixed d-flex flex-column flex-row-auto" id="kt_aside">
		<!--begin::Brand-->
		<div class="brand flex-column-auto" id="kt_brand">
			<!--begin::Logo-->
			<router-link to="/" class="brand-logo">
				<img alt="Logo" src="/assets/media/logos/MCM-TR-black.png" width="142" height="63" style="color: black;" />
			</router-link>
			<!--end::Logo-->
			<!-- <span class="font-weight-bolder font-size-h5">TR</span> -->
		</div>
		<!--end::Brand-->
		<!--begin::Aside Menu-->
		<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
			<!--begin::Menu Container-->
			<div id="kt_aside_menu" class="aside-menu my-4" data-menu-vertical="1" data-menu-scroll="1" data-menu-dropdown-timeout="500">
				<!--begin::Menu Nav-->
				<ul class="menu-nav">
					
					<!--begin::Dash-->
					<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.home')">
						<router-link to="/" class="menu-link" exact-active-class="routerLinkExactActive">
							<span class="svg-icon menu-icon">
								<!--begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg-->
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<polygon points="0 0 24 0 24 24 0 24" />
										<path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero" />
										<path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3" />
									</g>
								</svg>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">{{ $t('dash.title') }}</span>
						</router-link>
					</li>
					<!--end::Dash-->
					
					<!--begin::branch_dash-->
					<li class="menu-item" aria-haspopup="true"  v-if="checkPermission('crm.merchant.index')">
						<router-link to="/branch-dashboard" class="menu-link" exact-active-class="routerLinkExactActive">
							<span class="svg-icon menu-icon">
								<!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24"></rect>
										<path d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z" fill="#000000"></path>
										<path d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z" fill="#000000" opacity="0.3"></path>
									</g>
								</svg>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">{{ $t('branch_dash.title') }}</span>
						</router-link>
					</li>
					<!--end::branch_dash-->

					<!--begin::customer_management-->
					<li 
						class="menu-item menu-item-submenu"
						:class="{'menu-item-open': isCustomerMangementDropdownOpen()}"
						aria-haspopup="true"
						data-menu-toggle="hover"
						v-if="checkCustomerManagementPermission()"
					>
						<a href="javascript:;" class="menu-link menu-toggle">
							<span class="svg-icon menu-icon">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24"></rect>
										<rect fill="#000000" opacity="0.3" x="2" y="3" width="20" height="18" rx="2"></rect>
										<path d="M9.9486833,13.3162278 C9.81256925,13.7245699 9.43043041,14 9,14 L5,14 C4.44771525,14 4,13.5522847 4,13 C4,12.4477153 4.44771525,12 5,12 L8.27924078,12 L10.0513167,6.68377223 C10.367686,5.73466443 11.7274983,5.78688777 11.9701425,6.75746437 L13.8145063,14.1349195 L14.6055728,12.5527864 C14.7749648,12.2140024 15.1212279,12 15.5,12 L19,12 C19.5522847,12 20,12.4477153 20,13 C20,13.5522847 19.5522847,14 19,14 L16.118034,14 L14.3944272,17.4472136 C13.9792313,18.2776054 12.7550291,18.143222 12.5298575,17.2425356 L10.8627389,10.5740611 L9.9486833,13.3162278 Z" fill="#000000" fill-rule="nonzero"></path>
										<circle fill="#000000" opacity="0.3" cx="19" cy="6" r="1"></circle>
									</g>
								</svg>
							</span>
							<span class="menu-text">{{ $t('c_management.title') }}</span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu">
							<i class="menu-arrow"></i>
							<ul class="menu-subnav">
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.users.index')">
									<router-link to="/users" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('c_management.all_customer') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.users.quicksalebuy')">
									<router-link to="/users-quicksalebuy" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('c_management.trading_transactions') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.level-request.index')">
									<router-link to="/level-update" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('c_management.level_update') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.users.attachments.index')">
									<router-link to="/users-attachments" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('c_management.user_attachments') }}</span>
									</router-link>
								</li>
							</ul>
						</div>
					</li>
					<!--end::customer_management-->

					<!--begin::financial_management-->
					<li 
						class="menu-item menu-item-submenu"
						:class="{'menu-item-open': isFinancialMangementDropdownOpen()}"
						aria-haspopup="true"
						data-menu-toggle="hover"
						v-if="checkFinancialManagementPermission()"
					>
						<a href="javascript:;" class="menu-link menu-toggle">
							<span class="svg-icon menu-icon">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24"></rect>
										<rect fill="#000000" opacity="0.3" x="2" y="3" width="20" height="18" rx="2"></rect>
										<path d="M9.9486833,13.3162278 C9.81256925,13.7245699 9.43043041,14 9,14 L5,14 C4.44771525,14 4,13.5522847 4,13 C4,12.4477153 4.44771525,12 5,12 L8.27924078,12 L10.0513167,6.68377223 C10.367686,5.73466443 11.7274983,5.78688777 11.9701425,6.75746437 L13.8145063,14.1349195 L14.6055728,12.5527864 C14.7749648,12.2140024 15.1212279,12 15.5,12 L19,12 C19.5522847,12 20,12.4477153 20,13 C20,13.5522847 19.5522847,14 19,14 L16.118034,14 L14.3944272,17.4472136 C13.9792313,18.2776054 12.7550291,18.143222 12.5298575,17.2425356 L10.8627389,10.5740611 L9.9486833,13.3162278 Z" fill="#000000" fill-rule="nonzero"></path>
										<circle fill="#000000" opacity="0.3" cx="19" cy="6" r="1"></circle>
									</g>
								</svg>
							</span>
							<span class="menu-text">{{ $t('f_management.title') }}</span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu">
							<i class="menu-arrow"></i>
							<ul class="menu-subnav">
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.finance.deposit.index')">
									<router-link to="/finance/deposit" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('f_management.deposit_transactions') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.finance.withdraw.index')">
									<router-link to="/finance/withdraw" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('f_management.withdraw_transactions') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.finance.crypto-transaction.create')">
									<router-link to="/finance/crypto" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('f_management.crypto_transactions') }}</span>
									</router-link>
								</li>
							</ul>
						</div>
					</li>
					<!--end::financial_management-->

					<!--begin::merchant-->
					<li 
						class="menu-item menu-item-submenu"
						:class="{'menu-item-open': hasActiveChild('/merchant/transactions')}"
						aria-haspopup="true"
						data-menu-toggle="hover"
						v-if="checkPermission('crm.merchant.transactions.pending.data')"
					>
						<a href="javascript:;" class="menu-link menu-toggle">
							<span class="svg-icon menu-icon">
								<!--begin::Svg Icon | path:assets/media/svg/icons/Design/Bucket.svg-->
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24" />
										<path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3" />
										<path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000" />
									</g>
								</svg>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">{{ $t('merchant.title') }}</span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu">
							<i class="menu-arrow"></i>
							<ul class="menu-subnav">
								<li class="menu-item menu-item-parent" aria-haspopup="true">
									<span class="menu-link">
										<span class="menu-text">{{ $t('merchant.title') }}</span>
									</span>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.merchant.transactions.pending.data')">
									<router-link to="/merchant/transactions" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('merchant.all_transactions') }}</span>
									</router-link>
								</li>
							</ul>
						</div>
					</li>
					<!--end::merchant-->

					<!--begin::general_management-->
					<li 
						class="menu-item menu-item-submenu" 
						:class="{'menu-item-open': isGeneralMangementDropdownOpen()}"
						aria-haspopup="true" 
						data-menu-toggle="hover"
						v-if="checkGeneralManagementPermission()"
					>
						<a href="javascript:;" class="menu-link menu-toggle">
							<span class="svg-icon menu-icon">
								<!--begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg-->
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24"></rect>
										<path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000"></path>
										<path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3"></path>
									</g>
								</svg>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">{{ $t('general_management.title') }}</span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu">
							<i class="menu-arrow"></i>
							<ul class="menu-subnav">
								<li class="menu-item menu-item-parent" aria-haspopup="true">
									<span class="menu-link">
										<span class="menu-text">{{ $t('general_management.title') }}</span>
									</span>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.institutions.index')">
									<router-link to="/institutions" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('institutions.title') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.employees.index')">
									<router-link to="/employees" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('general_management.employees_title') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.authority.roles.index')">
									<router-link to="/roles" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('general_management.roles_title') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.authority.permissions.index')">
									<router-link to="/permissions-management" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('general_management.permissions_title') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.ip.index')">
									<router-link to="/ips" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('ips.title') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.finance.currencies.index')">
									<router-link to="/currencies" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('general_management.currencies_title') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.markets.index') && false">
									<router-link to="/markets-management" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('markets_management.title') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.finance.currencies.rates')">
									<router-link to="/rates" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('general_management.rates_title') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.providers.payments.index')">
									<router-link to="/payment-methods-management" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('general_management.payment_methods_title') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.bank.index')">
									<router-link to="/bank-management" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('general_management.bank_title') }}</span>
									</router-link>
								</li>
							</ul>
						</div>
					</li>
					<!--end::general_management-->

					<!--begin::financial_transactions-->
					<li 
						class="menu-item menu-item-submenu"
						:class="{'menu-item-open': isFinancialTransactionsDropdownOpen()}"
						aria-haspopup="true"
						data-menu-toggle="hover"
						v-if="false"
					>
						<a href="javascript:;" class="menu-link menu-toggle">
							<span class="svg-icon menu-icon">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24"></rect>
										<rect fill="#000000" opacity="0.3" x="2" y="3" width="20" height="18" rx="2"></rect>
										<path d="M9.9486833,13.3162278 C9.81256925,13.7245699 9.43043041,14 9,14 L5,14 C4.44771525,14 4,13.5522847 4,13 C4,12.4477153 4.44771525,12 5,12 L8.27924078,12 L10.0513167,6.68377223 C10.367686,5.73466443 11.7274983,5.78688777 11.9701425,6.75746437 L13.8145063,14.1349195 L14.6055728,12.5527864 C14.7749648,12.2140024 15.1212279,12 15.5,12 L19,12 C19.5522847,12 20,12.4477153 20,13 C20,13.5522847 19.5522847,14 19,14 L16.118034,14 L14.3944272,17.4472136 C13.9792313,18.2776054 12.7550291,18.143222 12.5298575,17.2425356 L10.8627389,10.5740611 L9.9486833,13.3162278 Z" fill="#000000" fill-rule="nonzero"></path>
										<circle fill="#000000" opacity="0.3" cx="19" cy="6" r="1"></circle>
									</g>
								</svg>
							</span>
							<span class="menu-text">{{ $t('commons.financial_transactions') }}</span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu">
							<i class="menu-arrow"></i>
							<ul class="menu-subnav">
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.financeapi.deposit.create')">
									<router-link to="/deposit" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('deposit.name') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.financeapi.withdraw.create')">
									<router-link to="/withdraw" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('withdraw.name') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true">
									<router-link to="/quickbuysell" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('quicksale.name') }}</span>
									</router-link>
								</li>
							</ul>
						</div>
					</li>
					<!--end::financial_transactions-->
					
					<!--begin::miracle_deal-->
					<li 
						class="menu-item menu-item-submenu" 
						:class="{'menu-item-open': isMiracleDealDropdownOpen()}"
						aria-haspopup="true"
						data-menu-toggle="hover"
						v-if="checkMiracleDealPermission()"
					>
						<a href="javascript:;" class="menu-link menu-toggle">
							<span class="svg-icon menu-icon">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24"/>
										<path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z" fill="#000000" opacity="0.3" transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "/>
										<path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z" fill="#000000"/>
									</g>
								</svg>
							</span>
							<span class="menu-text">{{ $t('miracle_deal.title') }}</span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu">
							<i class="menu-arrow"></i>
							<ul class="menu-subnav">
								<li class="menu-item" aria-haspopup="true">
									<router-link to="/keeper-list" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('miracle_deal.keeper_list') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true">
									<router-link to="/keeper-order" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('miracle_deal.keeper_order') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true">
									<router-link to="/striker-list" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('miracle_deal.striker_list') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true">
									<router-link to="/striker-order" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('miracle_deal.striker_order') }}</span>
									</router-link>
								</li>
								<li class="menu-item" aria-haspopup="true">
									<router-link to="/last-transactions" class="menu-link" exact-active-class="routerLinkExactActive">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ $t('miracle_deal.last_transactions') }}</span>
									</router-link>
								</li>
							</ul>
						</div>
					</li>
					<!--end::miracle_deal-->

					<!--begin::tv-->
					<li class="menu-item" aria-haspopup="true" v-if="checkPermission('crm.merchant.index')">
						<a href="/tv" class="menu-link" exact-active-class="routerLinkExactActive">
							<span class="svg-icon menu-icon">
								<!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24"></rect>
										<path d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z" fill="#000000"></path>
										<path d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z" fill="#000000" opacity="0.3"></path>
									</g>
								</svg>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">{{ $t('tv.title') }}</span>
						</a>
					</li>
					<!--end::tv-->

				</ul>
				<!--end::Menu Nav-->
			</div>
			<!--end::Menu Container-->
		</div>
		<!--end::Aside Menu-->
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: 'LeftMenu',
  computed: {
    ...mapState({
      permissions: (state) => state.customer.permissions,
    }),
  },
  methods: {
	...mapActions("customer", ["GET_PERMISSION"]),
    checkPermission(permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
	hasActiveChild(match) {
      return this.$route.path !== '/' && match.includes(this.$route.path);
    },
	checkPermissionMulti(incomePermissions) {
		if(this.permissions?.length > 0) {
			const mappedPermissions = this.permissions.map(val => val.name);
			return incomePermissions.some(permissionName => mappedPermissions.includes(permissionName));
		}
		return false
	},
	isCustomerMangementDropdownOpen() {
		return this.hasActiveChild('/users/users-quicksalebuy/level-update/users-attachments');
	},
	checkCustomerManagementPermission() {
		return this.checkPermissionMulti([
			'crm.users.index',
			'crm.users.quicksalebuy',
			'crm.level-request.index',
			'crm.users.attachments.index'
		]);
	},
	isFinancialMangementDropdownOpen() {
		return this.hasActiveChild('/finance/deposit/finance/withdraw/finance/crypto');
	},
	checkFinancialManagementPermission() {
		return this.checkPermissionMulti([
			'crm.finance.deposit.index',
			'crm.finance.withdraw.index',
			'crm.finance.crypto-transaction.create',
		]);
	},
	isGeneralMangementDropdownOpen() {
		return this.hasActiveChild('/institutions/employees/roles/permissions-management/ips/currencies/markets-management/rates/payment-methods-management/bank-management');
	},
	checkGeneralManagementPermission() {
		return this.checkPermissionMulti([
			'crm.institutions.index',
			'crm.employees.index',
			'crm.authority.roles.index',
			'crm.authority.permissions.index',
			'crm.ip.index',
			'crm.finance.currencies.index',
			'crm.markets.index',
			'crm.finance.currencies.rates',
			'crm.providers.payments.index',
			'crm.bank.index'
		]);
	},
	isFinancialTransactionsDropdownOpen() {
		return this.hasActiveChild('/deposit/withdraw/quickbuysell');
	},
	checkFinancialTransactionsPermission() {
		return this.checkPermissionMulti([
			'crm.financeapi.deposit.create',
			'crm.financeapi.withdraw.create'
		]);
	},
	isMiracleDealDropdownOpen() {
		return this.hasActiveChild('/keeper-list/keeper-order/striker-list/striker-order/last-transactions');
	},
	checkMiracleDealPermission() {
		return this.checkPermission('crm.merchant.index');
	}
  }
};
</script>
