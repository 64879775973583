// import Vue from 'vue';
// import i18n from '@/config/_i18n'
// import _ from 'lodash';
// import { getErrorServiceMsg } from '../../utils';


const cryptobanks = {
  state: () => ({
    isLoading: false,
    transactions: [],
    isHidden: false
  }),
  mutations: {
    SET_TRANSACTIONS(state, payload) {
      state.transactions = payload;
    },
    updateHidden(state, payload) {
      state.isHidden = payload;
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    }
  },
  actions: {
    async GET_TRANSACTIONS({ commit }, number) {
      /* commit('SET_LOADING', true);
      try {
        const res = await Services.get(`${API.crypto_transactions}?page=${number}`)
        let data = [];
        if(res.data.length > 0) {
          data = _.orderBy(res.data, 'createdAt', 'desc')
          data = data.slice(0, 10);
        }
        commit('SET_TRANSACTIONS', [data]);
      } catch (err) {
        Vue.$toast.error(getErrorServiceMsg(err?.response));
      } finally {
        commit('SET_LOADING', false);
      } */
      console.log(number);
      commit('SET_TRANSACTIONS', []);
    }
  },
  namespaced: true
}

export default cryptobanks;