<template>
  <div id="app" v-if="$route.path === '/404' || $route.path.indexOf('/2fa/qr-code')>-1">
    <router-view />
  </div>

  <div id="app" v-else-if="isAuthenticated" v-bind:class="$route.path === '/tv' ? 'tv' : ''">
    <LoadingOverlay v-if="isLoadingOverlay" />
    <HeaderMobile />
		<div class="d-flex flex-column flex-root">
			<div class="d-flex flex-row flex-column-fluid page">
        <LeftMenu />
				<div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
          <Header />
          <router-view />
          <Footer />
				</div>
			</div>
		</div>
    <ScrollTop />
  </div>

  <div id="app" v-else>
    <router-view />
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';

import LeftMenu from "@/components/navigation/LeftMenu.vue";
import HeaderMobile from "@/components/layout/HeaderMobile.vue";
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import ScrollTop from "@/components/navigation/ScrollTop.vue";

import LoadingOverlay from './components/custom/LoadingOverlay.vue';


export default {
  name: "App",
  components: {
    HeaderMobile,
    Header,
    Footer,
    LeftMenu,
    ScrollTop,
    LoadingOverlay
  },
  computed: {
    ...mapGetters('customer', ['isAuthenticated']),
    ...mapState({
      isLoadingOverlay: state => state.global.isLoadingOverlay
    })
  },
  watch: {
      $route: function() {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      }
  }
};
</script>

<style lang="scss">
@import "./assets/_style.custom.scss";
#app{
  height: 100%;
  width: 100%;
}
.multiselect.is-invalid .multiselect__tags {
  border-color: #dc3545;
}

.form-label-group,
.form-group {
.error__message {
  display: block;
  min-height: 17px;
}
}

.valid ~ .error__message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.invalid ~ .error__message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px!important;
}
.swal2-popup .swal2-icon {
  margin: 2rem auto 0rem auto;
}
.form-control.valid {
  border-color: #28D094;
}
.form-control.invalid {
  border-color: #FF4961;
}
.showPassword {
  height: 24px;
  width: 24px;
  position: absolute;
  right: 15px;
  top: 11px;
}
.passwordEye {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='%23777777' d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'%3E%3C/path%3E%3Ccircle stroke='%23777777' cx='12' cy='12' r='3'%3E%3C/circle%3E%3C/svg%3E");
}
.passwordEyeOff {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' %3E%3Cpath stroke='%23777777' d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'%3E%3C/path%3E%3Cline stroke='%23777777' x1='1' y1='1' x2='23' y2='23'%3E%3C/line%3E%3C/svg%3E");
}
#app.tv #kt_wrapper {
  padding-top: 75px;
}

.input-group.valid  {
  .input-group-text {
    border-color: #28D094;
  }
}
.input-group.invalid {
  .input-group-text {
  border-color: #FF4961;
}
}

</style>
