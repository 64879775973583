import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Login from '../views/Login/Login.vue';
import Dashboard from '../views/Dashboard.vue';
const BranchDashboard = () => import('../views/Branch/List.vue');
const BranchDashboardNew = () => import('../views/Branch/New.vue');
const BranchDashboardSummary = () => import('../views/Branch/Summary.vue');
const TransactionsList = () => import('../views/MerchantTransactions/List.vue');
const Employees = () => import('../views/GeneralManagement/Employees/List.vue');
const EmployeesDetails = () => import('../views/GeneralManagement/Employees/Details.vue');
const EmployeesNew = () => import('../views/GeneralManagement/Employees/New.vue');
const EmployeesUpdate = () => import('../views/GeneralManagement/Employees/Edit.vue');
const Ips = () => import('../views/GeneralManagement/Ips/List.vue');
const RoleList = () => import('../views/GeneralManagement/Roles/ListRole.vue');
const RolesNew = () => import('../views/GeneralManagement/Roles/New.vue');
const RolesUpdate = () => import('../views/GeneralManagement/Roles/Edit.vue');
const Institutions = () => import('../views/GeneralManagement/Institutions/List.vue');
const InstitutionsNew = () => import('../views/GeneralManagement/Institutions/New.vue');
const InstitutionsUpdate = () => import('../views/GeneralManagement/Institutions/Edit.vue');
const StrikerList = () => import('../views/MiracleDeal/StrikerList.vue');
const StrikerOrder = () => import('../views/MiracleDeal/StrikerOrder.vue');
const KeeperList = () => import('../views/MiracleDeal/KeeperList.vue');
const KeeperOrder = () => import('../views/MiracleDeal/KeeperOrder.vue');
const Tv = () => import('../views/MiracleDeal/Tv.vue');
const LastTransactions = () => import('../views/MiracleDeal/LastTransactions.vue');
const MyProfile = () => import('../views/Profile/MyProfile.vue');
const SecuritySettings = () => import('../views/Profile/MyProfile/SecuritySettings.vue');
const PasswordChange = () => import('../views/Profile/PasswordChange.vue');
const Deposit = () => import('../views/Deposit/Index.vue');
const Withdraw = () => import('../views/Withdraw/Index.vue');
const PermissionDenided = () => import('../components/custom/PermissionDenied');
const PasswordReset = () => import('../views/Login/PasswordReset.vue');
const QuickSale = () => import('../views/QuickSale/Index.vue');
const OutsidePurchase = () => import('../views/MerchantTransactions/OutsidePurchase.vue');
const DepositTransactions = () => import('../views/FinancialManagement/DepositTransactions.vue');
const WithdrawTransactions = () => import('../views/FinancialManagement/WithdrawTransactions.vue');
const CryptoTransactions = () => import('../views/FinancialManagement/CryptoTransactions.vue');
const AllCustomer = () => import('../views/CustomerManagement/AllCustomer.vue');
const LevelUpdate = () => import('../views/CustomerManagement/LevelUpdate.vue');
const TradingTransactions = () => import('../views/CustomerManagement/TradingTransactions.vue');
const UserAttachments = () => import('../views/CustomerManagement/UserAttachments.vue');
const CurrencyList = () => import('../views/GeneralManagement/Currencies/List.vue');
const EditCurrency = () => import('../views/GeneralManagement/Currencies/Edit.vue');
const RatesManagement = () => import('../views/GeneralManagement/Rates/Index.vue');
const PermissionsManagement = () => import('../views/GeneralManagement/Permissions/List.vue');
const PermissionsManagementNew = () => import('../views/GeneralManagement/Permissions/New.vue');
const PermissionsManagementUpdate = () => import('../views/GeneralManagement/Permissions/Edit.vue');
const BankManagement = () => import('../views/GeneralManagement/Bank/List.vue');
const BankManagementNew = () => import('../views/GeneralManagement/Bank/New.vue');
const BankManagementUpdate = () => import('../views/GeneralManagement/Bank/Edit.vue');
const PaymentMethodsManagement = () => import('../views/GeneralManagement/PaymentMethods/List.vue');
const PaymentMethodsManagementNew = () => import('../views/GeneralManagement/PaymentMethods/New.vue');
const PaymentMethodsManagementUpdate = () => import('../views/GeneralManagement/PaymentMethods/Edit.vue');
const IpsNew = () => import('../views/GeneralManagement/Ips/New.vue');
const IpsUpdate = () => import('../views/GeneralManagement/Ips/Edit.vue');
const MarketsManagement = () => import('../views/GeneralManagement/Markets/List.vue');
const UserDts = () => import('../views/UserDetails/Index.vue');
const UserDtsOverview = () => import('../views/UserDetails/Overview.vue');
const UserDtsEditUserInfo = () => import('../views/UserDetails/overview/EditUserInfo.vue');
const UserDtsExternalBalance = () => import('../views/UserDetails/ExternalBalance.vue');
const UserDtsAddExternalBalance = () => import('../views/UserDetails/external-balance/AddBalance.vue');
const UserDtsEditExternalBalance = () => import('../views/UserDetails/external-balance/EditBalance.vue');
const UserDtsFileManagement = () => import('../views/UserDetails/FileManagement.vue');
const UserDtsNotificationOptions = () => import('../views/UserDetails/NotificationOptions.vue')
const UserDtsFinancialTransactionHistory = () => import('../views/UserDetails/FinancialTransactionHistory.vue')
const UserDtsTransactionHistory = () => import('../views/UserDetails/TransactionHistory.vue');
const UserDtsAgentTransactionHistory = () => import('../views/UserDetails/AgentTransactionHistory.vue')
const UserTradingTransactionBuyFromOutside = () => import('../views/CustomerManagement/BuyFromOutpurchase.vue');
const TradingFailedTransactions = () => import('../views/CustomerManagement/TradingFailedTransactions.vue');
const DepositDetail = () => import('../components/financialManagement/deposit/Detail.vue');
const WithdrawDetail = () => import('../components/financialManagement/withdraw/Detail.vue');
const QrCode = () => import('../views/QrCode/QrCode.vue');
const UserKYCFineksus = () => import('../views/UserDetails/UserKYCFineksus.vue');

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['customer/isAuthenticated']) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters['customer/isAuthenticated']) {
    next();
    return;
  }
  next('/login');
};
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/branch-dashboard',
    name: 'BranchDashboard',
    component: BranchDashboard,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/branch-dashboard/new',
    name: 'BranchDashboardNew',
    component: BranchDashboardNew,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/branch-dashboard/summary',
    name: 'BranchDashboardSummary',
    component: BranchDashboardSummary,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/merchant/transactions',
    name: 'TransactionsList',
    component: TransactionsList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/employees',
    name: 'Employees',
    component: Employees,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/employees/details/:id',
    name: 'Employees Details',
    component: EmployeesDetails,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/employees/new',
    name: 'EmployeesNew',
    component: EmployeesNew,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/employees/update/:id',
    name: 'EmployeesUpdate',
    component: EmployeesUpdate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/ips',
    name: 'Ips',
    component: Ips,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/roles',
    name: 'RoleList',
    component: RoleList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/roles/new',
    name: 'RolesNew',
    component: RolesNew,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/roles/update/:id',
    name: 'RolesUpdate',
    component: RolesUpdate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/institutions',
    name: 'Institutions',
    component: Institutions,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/institutions/new',
    name: 'InstitutionsNew',
    component: InstitutionsNew,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/institutions/update/:id',
    name: 'InstitutionsUpdate',
    component: InstitutionsUpdate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/keeper-list',
    name: 'KeeperList',
    component: KeeperList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/keeper-order',
    name: 'KeeperOrder',
    component: KeeperOrder,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/striker-list',
    name: 'StrikerList',
    component: StrikerList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/striker-order',
    name: 'StrikerOrder',
    component: StrikerOrder,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: MyProfile,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/security-settings',
    name: 'SecuritySettings',
    component: SecuritySettings,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/password-change',
    name: 'PasswordChange',
    component: PasswordChange,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/last-transactions',
    name: 'LastTransactions',
    component: LastTransactions,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/tv',
    name: 'Tv',
    component: Tv,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Deposit,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: Withdraw,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/no-permission',
    name: 'NoPermission',
    component: PermissionDenided,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/password-reset/:token',
    name: 'PasswordReset',
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/quickbuysell',
    name: 'quickbuysell',
    component: QuickSale,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/outside-purchase',
    name: 'outside-purchase',
    component: OutsidePurchase,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users/:id',
    component: UserDts,
    beforeEnter: ifAuthenticated,
    redirect: "/users/:id/overview",
    children: [
      {
        path: 'overview',
        name: 'user_overview',
        component: UserDtsOverview
      },
      {
        path: 'edit-user-info',
        name: 'edit_user_info',
        component: UserDtsEditUserInfo
      },
      {
        path: 'external-balance',
        name: 'user_external_balance',
        component: UserDtsExternalBalance
      },
      {
        path: 'add-external-balance',
        name: 'user_add_external_balance',
        component: UserDtsAddExternalBalance
      },
      {
        path: 'edit-external-balance/:balance_id',
        name: 'user_edit_external_balance',
        component: UserDtsEditExternalBalance
      },
      {
        path: 'file-management',
        name: 'user_file_management',
        component: UserDtsFileManagement
      },
      {
        path: 'notification-options',
        name: 'user_notification_options',
        component: UserDtsNotificationOptions
      },
      {
        path: 'financial-transaction-history',
        name: 'user_financial_transaction_history',
        component: UserDtsFinancialTransactionHistory
      },
      {
        path: 'transaction-history',
        name: 'user_transaction_history',
        component: UserDtsTransactionHistory
      },
      {
        path: 'agent-transaction-history',
        name: 'user_agent_transaction_history',
        component: UserDtsAgentTransactionHistory
      },
      {
        path: 'kyc-fineksus',
        name: 'user_kyc_fineksus',
        component: UserKYCFineksus
      }
    ]
  },
  {
    path: '/finance/deposit',
    name: 'finance.deposit',
    component: DepositTransactions,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/finance/withdraw',
    name: 'finance.withdraw',
    component: WithdrawTransactions,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/finance/deposit/:id',
    name: 'finance.deposit.detail',
    component: DepositDetail,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/finance/withdraw/:id',
    name: 'finance.withdraw.detail',
    component: WithdrawDetail,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/finance/crypto',
    name: 'finance.crypto',
    component: CryptoTransactions,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'users',
    component: AllCustomer,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users-quicksalebuy',
    name: 'users.quicksalebuy',
    component: TradingTransactions,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/level-update',
    name: 'level.update',
    component: LevelUpdate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users-attachments',
    name: 'users.attachments',
    component: UserAttachments,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/currencies',
    name: 'Currencies',
    component: CurrencyList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/currencies/:id',
    name: 'EditCurrency',
    component: EditCurrency,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/rates',
    name: 'RatesManagement',
    component: RatesManagement,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/permissions-management',
    name: 'PermissionsManagement',
    component: PermissionsManagement,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/permissions-management/new',
    name: 'PermissionsManagementNew',
    component: PermissionsManagementNew,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/permissions-management/update/:id',
    name: 'PermissionsManagementUpdate',
    component: PermissionsManagementUpdate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/bank-management',
    name: 'BankManagement',
    component: BankManagement,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/bank-management/new',
    name: 'BankManagementNew',
    component: BankManagementNew,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/bank-management/update/:id',
    name: 'BankManagementUpdate',
    component: BankManagementUpdate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/payment-methods-management',
    name: 'PaymentMethodsManagement',
    component: PaymentMethodsManagement,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/payment-methods-management/new/:type',
    name: 'PaymentMethodsManagementNew',
    component: PaymentMethodsManagementNew,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/payment-methods-management/update/:id',
    name: 'PaymentMethodsManagementUpdate',
    component: PaymentMethodsManagementUpdate,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/ips/new',
    name: 'IpsNew',
    component: IpsNew,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/ips/update/:id',
    name: 'IpsUpdate',
    component: IpsUpdate,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/markets-management',
    name: 'MarketsManagement',
    component: MarketsManagement,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users-quicksalebuy/out-purchases/:id',
    name: 'users.quicksalebuy.out_purchases',
    component: UserTradingTransactionBuyFromOutside,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users-quicksalebuy/failed-transactions',
    name: 'users.quicksalebuy.failed_transactions',
    component: TradingFailedTransactions,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/2fa/qr-code/:key1/:key2/:key3',
    name: 'qr-code',
    component: QrCode
  },  
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
