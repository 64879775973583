import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import API_OLD from '@/api/index_old';
import ServicesOld from "../../config/_axios_old";
import { objectToArrayOfObjects } from '../../utils';
//import i18n from '@/config/_i18n'
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';

const employees = {
  state: () => ({
    employees: [],
    selected_employees: [],
    isLoading: true,
    employees_new_get: {
      statuses: [],
      departments: [],
      institutions: []
    },
    checked_authority_roles: [],
    authority_roles: [],
    employees_audit: [],
    showAuditModal: false
  }),
  mutations: {
    SET_EMPLOYESS(state, payload) {
      state.employees = payload;
    },
    SET_EMPLOYESS_BY_ID(state, payload) {
      state.selected_employees = payload;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_EMPLOYESS_NEW(state, payload) {
      state.employees_new_get = payload;
    },
    SET_AUTHORITY_ROLES(state, payload) {
      state[payload.stateName] = payload.data;
    },

    SET_EMPLOYESS_AUDIT(state, payload) {
      state.employees_audit = payload;
    },
    TOGGLE_AUDIT_MODAL(state, payload) {
      state.showAuditModal = payload;
      if (!payload) {
        state.employees_audit=[];
      }
    },
  },
  actions: {
    async GET_EMPLOYESS({ commit }) {
      await ServicesOld.post(API_OLD.employees_data, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(res => {
          console.log("employess: ", res.data.data)
          commit('SET_EMPLOYESS', res.data.data);
        })
        .catch(err => {
            console.log("err: ",err.response)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
        });
    },
    GET_EMPLOYESS_BY_ID({ commit }, id) {
      Services.get(`${API.employees}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(res => {
          console.log("employess_id: ", res.data)
          commit('SET_EMPLOYESS_BY_ID', res.data);
        })
        .catch(err => {
            console.log("err: ",err.response)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
        });
    },
    async GET_NEW_EMPLOYESS({ commit }) {
      commit('IS_LOADING', true);
      try {
        const res = await ServicesOld.get(`${API_OLD.employees_new_get}`);
        // console.log("employess GET: ", res.data)
        let institutions = [];
        if(res.data.institutions && typeof res.data.institutions === "object" && Object.keys(res.data.institutions).length > 0) {
          // eslint-disable-next-line no-unused-vars
          institutions = Object.entries(res.data.institutions).map(([key, value]) => ({...value}));
        }
        if(res.data.institutions && Array.isArray(res.data.institutions)) {
          institutions = res.data.institutions;
        }
        commit('SET_EMPLOYESS_NEW', {
          ...res.data,
          statuses: objectToArrayOfObjects(res.data?.statuses),
          departments: objectToArrayOfObjects(res.data?.departments),
          institutions: institutions
        });
      } catch (err) {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
      } finally {
        commit('IS_LOADING', false);
      }
    },

    GET_EMPLOYESS_AUDIT({ commit }, id) {
      commit('IS_LOADING', true);
      ServicesOld.post(`${API_OLD.employees_audit}`, {"id":id,"model": "App\\\Models\\\Employee\\\Employee"}) // eslint-disable-line
          .then(res => {
            console.log("GET_EMPLOYESS_AUDIT: ", res.data)
            commit('SET_EMPLOYESS_AUDIT', res.data.audits);
            commit('TOGGLE_AUDIT_MODAL', true);
          })
          .catch(err => {
            console.log("err: ",err.response)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          })
          .finally(() => {
            commit('IS_LOADING', false);
          })
    },
    async POST_EMPLOYESS({ commit }, params) {
      try {
        const res = await ServicesOld.post(`${API_OLD.employees_new}`, params);
        console.log("post: ", res.data)
        commit('IS_LOADING', false);
        Vue.$toast.success(getSuccessServiceMsg(res));
      } catch (err) {
        console.log("err: ",err.response)
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
      }
    },
    EDIT_EMPLOYESS({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('IS_LOADING', true);
        ServicesOld.get(`${API_OLD.employees}/${id}/edit`)
          .then(res => {
            let institutions = [];
            if(res.data.institutions && typeof res.data.institutions === "object" && Object.keys(res.data.institutions).length > 0) {
              // eslint-disable-next-line no-unused-vars
              institutions = Object.entries(res.data.institutions).map(([key, value]) => ({...value}));
            }
            if(res.data.institutions && Array.isArray(res.data.institutions)) {
              institutions = res.data.institutions;
            }
            commit('SET_EMPLOYESS_NEW', {
              ...res.data,
              statuses: objectToArrayOfObjects(res.data?.statuses),
              departments: objectToArrayOfObjects(res.data?.departments),
              institutions: institutions
            });
            resolve('success')
          })
          .catch(err => {
            console.log("err: ",err.response)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
            reject('error')
          })
          .finally(() => {
            commit('IS_LOADING', false);
          })
      })
    },
    UPDATE_EMPLOYESS({ commit }, params) {
      ServicesOld.patch(`${API_OLD.employees}/${params.id}/update`, params.data)
        .then(res => {
          console.log("post: ", res.data)
          commit('IS_LOADING', false);
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    async DELETE_EMPLOYESS({ commit }, id) {
      try {
        const res = await ServicesOld.delete(`${API_OLD.employees}/${id}`);
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
        
      } catch (err) {
        console.log("err: ",err.response)
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        return err;
      } finally {
        commit('IS_LOADING', false);
      }
    },
    TWO_FACTOR_EMPLOYESS({ commit }, id) {
      ServicesOld.post(`${API_OLD.employees_2fa}${id}`)
        .then(res => {
          console.log("employess delete: ", res.data)
          commit('IS_LOADING', false);
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err.response)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    async GET_EMPLOYEE_AUTHORITY_ROLES({ commit }, id) {
      try {
        const resp = await ServicesOld.get(`${API_OLD.employees}/show/${id}/permissions`)
        commit('SET_AUTHORITY_ROLES', {stateName: 'authority_roles', data: resp.data.roles});
        const checkedRoles = Object.keys(resp.data.employeeRoles);
        commit('SET_AUTHORITY_ROLES', {stateName: 'checked_authority_roles', data: checkedRoles});
      } catch (err) {
        console.log('action employees/GET_EMPLOYEE_AUTHORITY_ROLES error', err.response.data);
        if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
        }
      }
    },
    async POST_EMPLOYEE_AUTHORITY_ROLES(_, {id, roles}) {
      try {
        const resp = await ServicesOld.post(`${API_OLD.employees}/show/${id}/permissions`, { roles })
        console.log(resp.data.message);
        Vue.$toast.success(getSuccessServiceMsg(resp));
      } catch (err) {
        console.log('action employees/POST_EMPLOYEE_AUTHORITY_ROLES error', err.response.data);
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
      }
    },
    SHOW_AUDIT_MODAL({ commit }) {
      commit('TOGGLE_AUDIT_MODAL', true);
    },
    CLOSE_AUDIT_MODAL({ commit }) {
      commit('TOGGLE_AUDIT_MODAL', false);
    },
  },
  namespaced: true
}

export default employees;
