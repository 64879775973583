import Vue from 'vue';
import ServicesOld from "../../config/_axios_old";
import ServicesDeal from "../../config/_axios_deal";
import API_OLD from '../../api/index_old';
import { getErrorServiceMsg } from '../../utils';

const currencies = {
  state: () => ({
    total_user_balances: [],
    currencies: [],
    total_page: 0,
    isLoading: true,
    merchantCurrencies: []
  }),
  mutations: {
    SET_CURRENCIES(state, payload) {
      state.currencies = payload;
    },
    SET_TOTAL_USER_BALANCES(state, payload) {
      state.total_user_balances = payload;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_MERCHANT_CURRENCIES(state, payload) {
      state.merchantCurrencies = payload;
    },
  },
  actions: {
    async GET_TOTAL_USER_BALANCES({ commit }) {
      commit('IS_LOADING', true);
      try {
        const res = await ServicesOld.post(`${API_OLD.totalByCurrencyForUserBalances}`, {
          headers: {
            'Content-Type': 'application/json',
            //'Accept': 'application/json'
          }
        })
        // console.log("total user: ", res.data);
        commit('SET_TOTAL_USER_BALANCES', res.data?.totalCoin || []);
        commit('IS_LOADING', false);
        
      } catch (err) {
        // console.log("err: ", err.response)
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
      } finally {
        commit('IS_LOADING', false);
      }
    },
    GET_CURRENCIES({ commit }) {
      return new Promise((resolve, reject) => {
        commit('IS_LOADING', true);
        ServicesDeal.get(API_OLD.currencies, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then(res => {
            /* console.log(res);
            console.log(res.data); */
            commit('SET_CURRENCIES', res.data);
            resolve('success')
          })
          .catch(err => {
            reject('error')
            console.log("err: ", err.response)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          })
          .finally(() => {
            commit('IS_LOADING', false);
          })
      })
    },
    async GET_MERCHANT_CURRENCIES({ commit }) {
      try {
        const res = await ServicesOld.get(API_OLD.merchant_currencies);
        commit('SET_MERCHANT_CURRENCIES', res.data?.data ?? [])
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      }
    }
  },
  namespaced: true
}

export default currencies;
