<template>
	<div class="brand subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ datas.title }}</h5>
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
            <li v-for='(item, index) in datas.subtitle' :key="'history-' + index" class="breadcrumb-item text-muted">
              <a href="javascript:;" class="text-muted" v-if="!item.route || (typeof item === 'string')">{{ item }}</a>
              <router-link :to="item.route" class="breadcrumb-item text-muted" v-else>{{ item.title }}</router-link>
            </li>
          </ul>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>	
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    datas: Object
  },
};
</script>
