import API_OLD from '@/api/index_old';
import Vue from "vue";
import ServicesOld from "../../config/_axios_old";
import ServicesDeal from "../../config/_axios_deal";
var moment = require('moment');
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';

const keeper = {
  state: () => ({
    keeper: [],
    isLoading: true,
    searchIsLoading: false,
    userList: [],
    keeperList: [],
    showKeeperModal: false,
    selectedKeeperId: '',
  }),
  mutations: {
    SET_KEEPER(state, payload) {
      state.keeperList=[];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.processDate?.date, "YYYY-MM-DD HH:mm:ssZ");
        var timeAgo = actionTime.fromNow();
        state.keeperList.push(Object.assign({ time_ago: timeAgo }, payload[key]))
      }
    },
    IS_LOADING(state, payload) {
        state.isLoading = payload;
    },
    SEARCH_IS_LOADING(state, payload) {
      state.searchIsLoading = payload;
    },
    SET_SEARCH_KEEPER(state, payload) {
      state.userList = payload;
    },
    statusModal(state, payload) {
      state.showKeeperModal = payload;
    },
    SET_KEEPER_ID(state, payload) {
      state.selectedKeeperId = payload;
    }
  },
  actions: {
    async GET_KEEPER({ commit }, params) {
      commit('IS_LOADING', true);
      await ServicesDeal.get(`${API_OLD.keeper}?order=DESC`, params)
        .then(res => {
          commit('SET_KEEPER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_KEEPER_TV({ commit }, params) {
      await ServicesDeal.get(`${API_OLD.keeper}?limit=10&order=DESC`, params)
        .then(res => {
          commit('SET_KEEPER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async POST_KEEPER({ commit }, params) {
      commit('IS_LOADING', true);
      try {
        const res = await ServicesDeal.post(`${API_OLD.keeper_order}`, params);
        console.log("POST_KEEPER: ",res)
        if(res.data.success) Vue.$toast.success(getSuccessServiceMsg(res));
        else Vue.$toast.error(getErrorServiceMsg(res));
      } catch (err) {
        console.log("err: ",err)
        if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('IS_LOADING', false);
      }
    },
    POST_KEEPER_NEW({ commit }, params) {
      commit('IS_LOADING', true);
      ServicesDeal.post(`${API_OLD.keeper_new}`, params)
        .then(res => {
          console.log("POST__KEEPER_NEW: ",res)
          commit('IS_LOADING', false);
          commit('statusModal', false);
          if(res.data.success) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('striker/GET_STRIKER', {root: true});
          }
          else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    GET_STRIKER_LIST({ commit }, params) {
      commit('IS_LOADING', true);
      ServicesDeal.get(`${API_OLD.transactions_calculate}`, params)
        .then(res => {
          console.log("GET_STRIKER_LIST: ",res)
          commit('IS_LOADING', false);
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    SEARCH_KEEPER({ commit }, params) {
      console.log("SEARCH_KEEPER: ",params)
      commit('SEARCH_IS_LOADING', true);
      ServicesOld.get(`${API_OLD.user_search}?type=corporate&search=${params}`)
        .then(res => {
          console.log("SEARCH_KEEPER: ",res)
          commit('SEARCH_IS_LOADING', false);
          commit('SET_SEARCH_KEEPER', res.data.results);
          //Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err)
          commit('SEARCH_IS_LOADING', false);
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    DELETE_KEEPER({ commit }, id) {
      ServicesDeal.post(`${API_OLD.keeper_rollback}${id}`)
        .then(res => {
          console.log("DELETE_STRIKER: ", res)
          commit('IS_LOADING', false);
          if(res.data.success == true) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('keeper/GET_KEEPER', {root: true});
          }
          else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch(err => {
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
  },
  namespaced: true
}

export default keeper;
