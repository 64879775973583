const jQuery = require("jquery");
window.jQuery = window.$ = jQuery;
import "datatables.net-bs4";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons-bs4";
window.JSZip = require("jszip"); // fix export excel problem
import "jszip";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-datetime";

// styling
import "./datatable.scss";
