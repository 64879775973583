import Vue from 'vue';
import API_OLD from '../../api/index_old';
import ServicesOld from "../../config/_axios_old";
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';
// import i18n from '../../config/_i18n'
import router from '../../router/index';

const customerManagement = {
  state: () => ({
    isLoading: false,
    trading_data: [],
    level_update_data: [],
    attachment_data: [],
    failed_trading_data: [],
    level_update_audit_visible: false,
    level_update_audit_data: [],
    level_update_details_modal_visible: false,
    level_update_details_modal_data: {
      user: {},
      levelRequest: {},
      attachments: [],
      levelService: {}
    },
    level_update_sms_modal_visible: false,
    level_update_sms_modal_form: {
      request_id: '',
      action: '',
      sms: ''
    },
    buyFromOutPurchaseData: {
      currencies: [],
      model: '',
      model_id: '',
      transaction: {}
    },
  }),
  mutations: {
    SET_TRADING_DATA(state, payload) {
      state.trading_data = payload;
    },
    SET_LEVEL_UPDATE_DATA(state, payload) {
      state.level_update_data = payload
    },
    SET_ATTACHMENT_DATA(state, payload) {
      state.attachment_data = payload
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_STATE(state, payload) {
      state[payload.field] = payload.value;
    },
    TOGGLE_LEVEL_UP_AUDIT_MODAL(state, payload) {
      state.level_update_audit_visible = payload;
      if(!payload) {
        state.level_update_audit_data = [];
      }
    },
    TOGGLE_LEVEL_UP_DETAILS_MODAL(state, payload) {
      state.level_update_details_modal_visible = payload;
      if(!payload) {
        state.level_update_details_modal_data = {
          user: {},
          levelRequest: {},
          attachments: [],
          levelService: {}
        };
      }
    },
    TOGGLE_LEVEL_UP_SMS_MODAL(state, payload) {
      state.level_update_sms_modal_visible = payload;
      if(!payload) {
        state.level_update_sms_modal_form = {
          request_id: '',
          action: '',
          sms: ''
        };
      }
    },
  },
  actions: {
    async GET_TRADING_DATA({ commit }) {
      commit('SET_LOADING', true);
      try {
        const res = await ServicesOld.get(API_OLD.trading_data)
        commit('SET_TRADING_DATA', res.data.data);
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async GET_FAILED_TRADING_DATA({ commit }, isLoading = true) {
      isLoading && commit('SET_LOADING', true);
      try {
        const res = await ServicesOld.get(API_OLD.failed_trading_data)
        commit('SET_STATE', { field: 'failed_trading_data', value: res.data?.data ?? [] });
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async GET_LEVEL_UPDATE_DATA({ commit }) {
      commit('SET_LOADING', true);
      try {
        const res = await ServicesOld.post(API_OLD.level_update_data)
        commit('SET_LEVEL_UPDATE_DATA', res.data.data);
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async GET_LEVEL_UP_AUDIT({ commit }, id) {
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.post(API_OLD.customer_audit_modal, { id, model: "App\\Models\\Common\\LevelRequest" })
        commit('SET_STATE', { field: 'level_update_audit_data', value: res?.data?.audits ?? [] });
        commit('TOGGLE_LEVEL_UP_AUDIT_MODAL', true);
        return res;
      } catch (err) {
        if(err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },
    async GET_LEVEL_UP_DETAILS_MODAL({ commit }, id) {
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.get(API_OLD.level_request_details_modal(id))
        const { user, levelRequest, attachments, levelService } = res.data?.data;
        commit('SET_STATE', { 
          field: 'level_update_details_modal_data',
          value: {
            user: user ?? {},
            levelRequest: levelRequest ?? {},
            attachments: attachments ?? [],
            levelService: levelService ?? {}
          }
        });
        commit('TOGGLE_LEVEL_UP_DETAILS_MODAL', true);
        return res;
      } catch (err) {
        if(err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },
    /**
     * 
     * @param {*} param0 
     * @param {*} param1 
     * decline process action = 30, approve process action = 20
     * 1 send sms code request
     * 2 open sms modal
     */
    async LEVEL_UP_SEND_SMS({ commit, state }, { id, action }) {

      // mock request
      /* commit('global/SET_OVERLAY_LOADER', true, { root: true })
      setTimeout(() => {
        commit('SET_STATE', {
          field: 'level_update_sms_modal_form',
          value: {
            ...state.level_update_sms_modal_form,
            request_id: id,
            action
          }
        })
        commit('TOGGLE_LEVEL_UP_SMS_MODAL', true);
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 500) */

      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.post(API_OLD.level_request_sms(id), {})
        commit('SET_STATE', {
          field: 'level_update_sms_modal_form',
          value: {
            ...state.level_update_sms_modal_form,
            request_id: id,
            action
          }
        })
        commit('TOGGLE_LEVEL_UP_SMS_MODAL', true);
        return res;
      } catch (err) {
        if(err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },

    /**
     * 
     * @param {*} param0 
     * @param {*} id 
     * 3 confirm sms - and approve or decline level request
     * 4 dispatch('GET_LEVEL_UP_DETAILS_MODAL')
     * 5 close sms modal
     */
    async LEVEL_UP_CONFIRM_SMS({ commit, state, dispatch }) {

      // mock request
      /* commit('global/SET_OVERLAY_LOADER', true, { root: true })
      setTimeout(() => {
        console.log(state.level_update_sms_modal_form)
        commit('TOGGLE_LEVEL_UP_SMS_MODAL', false);
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 500) */

      const { request_id, ...rest } = state.level_update_sms_modal_form;

      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.post(API_OLD.level_request_details_modal(request_id), { ...rest })
        commit('TOGGLE_LEVEL_UP_SMS_MODAL', false);
        dispatch('GET_LEVEL_UP_DETAILS_MODAL', request_id);
        Vue.$toast.success(getSuccessServiceMsg(res));
      } catch (err) {
        if(err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },

    async GET_ATTACHMENT_DATA({ commit }) {
      commit('SET_LOADING', true);
      try {
        const res = await ServicesOld.post(API_OLD.attachment_data)
        commit('SET_ATTACHMENT_DATA', res.data.data);
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async ATTACHMENT_CONFIRMATION({ commit, dispatch }, { fileId, action, levelRequestId }) {
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.post(API_OLD.level_request_attachment_confirmation(fileId), { action })
        dispatch('GET_LEVEL_UP_DETAILS_MODAL', levelRequestId);
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },
    
    async TRADING_OUT_PURCHASE_CREATE({ commit }, id) {
      commit('SET_LOADING', true);
      try {
        const res = await ServicesOld.get(API_OLD.trading_buyfromoutpurchase_create(id));
        const { currencies, model, model_id, transaction } = res.data;
        commit('SET_STATE', {
          field: 'buyFromOutPurchaseData',
          value: {
            currencies: currencies ?? [],
            model: model ?? '',
            model_id: model_id ?? '',
            transaction: transaction ?? {}
          }
        })
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async TRADING_OUT_PURCHASE_CRYPTOBANKS_CREATE({ commit }, id) {
      commit('SET_LOADING', true);
      try {
        const res = await ServicesOld.get(API_OLD.trading_buyfromoutpurchase_cryptobanks_create(id));
        const { currencies, model, model_id, transaction } = res.data;
        commit('SET_STATE', {
          field: 'buyFromOutPurchaseData',
          value: {
            currencies: currencies ?? [],
            model: model ?? '',
            model_id: model_id ?? '',
            transaction: transaction ?? {}
          }
        })
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async TRADING_OUT_PURCHASE_STORE({ commit }, formData) {
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.post(API_OLD.trading_buyfromoutpurchase_store, formData);
        Vue.$toast.success(getSuccessServiceMsg(res));
        router.push({ name: 'users.quicksalebuy' })
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    }

  },
  namespaced: true
}

export default customerManagement;